// src/components/layout.js

import * as React from "react";

import { Header } from "./header";
import { Footer } from "./footer";

/*
 * *** Layout  ***
 * ----------------
 */


const Layout = ({ location, children, ...props }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  return (
    <div className="px-2 md:px-4">
      <Header isRootPath={isRootPath} {...props} />
        {children}
      <Footer {...props} />
    </div>
  );
};

export default Layout;
