// src/components/footer.js
import * as React from "react";
import { Link } from "gatsby";
import {FaLinkedin}  from "react-icons/fa"

import useSiteMetadata from "../utils/useSiteMetadata";

/*
 * *** Footer  ***
 * ---------------
 */

export const Footer = () => {
  const {
    author: { name },
    social: { linkedin },
  } = useSiteMetadata();

  return (
    <footer className="py-8 lg:ml-72 ">
      <div className="flex">
        © {new Date().getFullYear()} {name}, who you can find on
        <a href={linkedin} className="flex items-center pl-1">
          <FaLinkedin className="pr-1 text-xl text-gray-800" /> LinkedIn
        </a>
      </div>
      <Link to="/privacy" className="ml-4" itemProp="url">
        Imprint & Privacy Information
      </Link>
    </footer>
  );
};
