// src/components/header.js
import * as React from "react";
import { Link } from "gatsby";
import {useIsMobil} from "../libs/hooks/useResponsiveShortcut"
import useSiteMetadata from "../utils/useSiteMetadata";

/*
 * *** Header  ***
 * ---------------
 */


export const Header = ({ isRootPath }) => {
  const {
    author: { name, summary },
  } = useSiteMetadata();

  const isMobil = useIsMobil();

  const wHeader = isMobil ? "mr-6" : "w-72";
  const mLinks = isMobil ? "mr-3" : "mr-6";

  const labelBlog = isMobil ? "Blog" : "Blog 2012-2017"
  const labelReading = isMobil ? "Reading" : "Reading-List";

  const fontSerifSemibold =  isMobil  ? "baseSerifSemibold" :"lgSerifSemibold";
  const fontSerifNormal = isMobil ? "baseSerif" : "lgSerif";

  let header;

  if (isRootPath) {
    header = (
      <h1 className={`${wHeader} ${fontSerifSemibold}`}>
        <Link to="/">
          {name},<br />
          <span className="italic font-normal">{summary}</span>
        </Link>
      </h1>
    );
  } else {
    header = (
      <Link className={`${wHeader} ${fontSerifSemibold}`} to="/">
        {name},<br />
        <span className="italic font-normal">{summary}</span>
      </Link>
    );
  }

  return (
    <header className="flex items-end pt-6 pb-16">
      {header}
      <ul className={`${fontSerifNormal}`}>
        <Link className={mLinks} to="/portfolio">
          Portfolio
        </Link>
        <Link className={mLinks} to="/blog">
          {labelBlog}
        </Link>
        {/* <Link className="" to="/reading-list">
          {labelReading}
        </Link> */}
      </ul>
    </header>
  );
};
