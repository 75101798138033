// src/hooks/useSiteMetadata.js

import { useStaticQuery, graphql } from "gatsby";

/*
 * *** useSiteMetadata  ***
 * -------------------------
 */

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
            social {
              linkedin
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
